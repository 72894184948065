/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 v-for="(block, index) in dragBlocks" :key="block + index" class="dragBlock font-semibold cursor-pointer">{{ block }}</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0 pb-0 pl-4">
              <div class="vx-row rounded-lg p-4 bg-light main-border">
                <div class="vx-col w-6/12 flex items-center pl-0">
                  <font-awesome-icon icon="fa-solid fa-computer" class="h-12 w-12 mr-6" style="color: #3a4c6a" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input6">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12 flex items-center justify-between pr-0">
                  <div class="flex-col">
                    <h4 class="font-light">- End User layer</h4>
                    <h4 class="font-light">- HTTP, FTP, IRC, SSH, DNS</h4>
                  </div>
                  <h1 class="bg-lightest rounded-lg w-16 h-16 flex items-center justify-center">7</h1>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 bg-light main-border mt-4">
                <div class="vx-col w-6/12 flex items-center pl-0">
                  <font-awesome-icon icon="fa-solid fa-images" class="h-12 w-12 mr-6" style="color: #3a4c6a" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input5">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12 flex items-center justify-between pr-0">
                  <div class="flex-col">
                    <h4 class="font-light">- Syntax layer</h4>
                    <h4 class="font-light">- SSL, SSH, IMAP, FTP, MPEG, JPEG</h4>
                  </div>
                  <h1 class="bg-lightest rounded-lg w-16 h-16 flex items-center justify-center">6</h1>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 bg-light main-border mt-4">
                <div class="vx-col w-6/12 flex items-center pl-0">
                  <font-awesome-icon icon="fa-solid fa-bridge" class="h-12 w-12 mr-6" style="color: #3a4c6a" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input4">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12 flex items-center justify-between pr-0">
                  <div class="flex-col">
                    <h4 class="font-light">- Synch & sent to port</h4>
                    <h4 class="font-light">- API's, Sockets, WinSock</h4>
                  </div>
                  <h1 class="bg-lightest rounded-lg w-16 h-16 flex items-center justify-center">5</h1>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 bg-light main-border mt-4">
                <div class="vx-col w-6/12 flex items-center pl-0">
                  <font-awesome-icon
                    icon="fa-solid fa-arrow-right-arrow-left"
                    class="h-12 w-12"
                    style="color: #3a4c6a; margin-right: 1.9rem"
                  />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input3">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12 flex items-center justify-between pr-0">
                  <div class="flex-col">
                    <h4 class="font-light">- End-to-end Connections</h4>
                    <h4 class="font-light">- TCP, UDP</h4>
                  </div>
                  <h1 class="bg-lightest rounded-lg w-16 h-16 flex items-center justify-center">4</h1>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 bg-light main-border mt-4">
                <div class="vx-col w-6/12 flex items-center pl-0">
                  <font-awesome-icon icon="fa-solid fa-wifi" class="h-12 w-12 mr-6" style="color: #3a4c6a" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input2">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12 flex items-center justify-between pr-0">
                  <div class="flex-col">
                    <h4 class="font-light">- Packets</h4>
                    <h4 class="font-light">- IP, ICMP, IPSec, IGMP</h4>
                  </div>
                  <h1 class="bg-lightest rounded-lg w-16 h-16 flex items-center justify-center">3</h1>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 bg-light main-border mt-4">
                <div class="vx-col w-6/12 flex items-center pl-0">
                  <font-awesome-icon icon="fa-solid fa-network-wired" class="h-12 w-12 mr-6" style="color: #3a4c6a" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input1">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12 flex items-center justify-between pr-0">
                  <div class="flex-col">
                    <h4 class="font-light">- Frames</h4>
                    <h4 class="font-light">- Ethernet, PPP, Switch, Bridge</h4>
                  </div>
                  <h1 class="bg-lightest rounded-lg w-16 h-16 flex items-center justify-center">2</h1>
                </div>
              </div>
              <div class="vx-row rounded-lg p-4 bg-light main-border mt-4">
                <div class="vx-col w-6/12 flex items-center pl-0">
                  <font-awesome-icon icon="fa-solid fa-ethernet" class="h-12 w-12 mr-6" style="color: #3a4c6a" />
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" id="input0">
                  </vue-draggable-container>
                </div>
                <div class="vx-col w-6/12 flex items-center justify-between pr-0">
                  <div class="flex-col">
                    <h4 class="font-light">- Physical Structure</h4>
                    <h4 class="font-light">- Coax, Fiber, Wireless, Hubs, Repeaters</h4>
                  </div>
                  <h1 class="bg-lightest rounded-lg w-16 h-16 flex items-center justify-center">1</h1>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      dragBlocks: ['Application', 'Presentation', 'Session', 'Transport', 'Network', 'Data Link', 'Physical'],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },

    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'Physical') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Data Link') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Network') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Transport') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === 'Session') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input5').textContent === 'Presentation') {
        document.getElementById('input5').style.borderColor = '#28C76F';
        document.getElementById('input5').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input6').textContent === 'Application') {
        document.getElementById('input6').style.borderColor = '#28C76F';
        document.getElementById('input6').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input6').style.borderColor = '#fd4445';
        document.getElementById('input6').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  created() {
    this.dragBlocks = shuffle(this.dragBlocks);
  },
  components: {
    draggable,
    shuffle,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
#wordBank {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.6rem;
}
</style>
